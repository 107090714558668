// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import TaskAllocator from "../../blocks/TaskAllocator/src/TaskAllocator";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import Documentation from "../../blocks/Documentation/src/Documentation";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import DocumentDistribution from "../../blocks/documentdistribution/src/DocumentDistribution";
import ProposalGeneration2 from "../../blocks/ProposalGeneration2/src/ProposalGeneration2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import AutomatedEmailSending from "../../blocks/AutomatedEmailSending/src/AutomatedEmailSending";
import AdminConsole2 from "../../blocks/AdminConsole2/src/AdminConsole2";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Notifications from "../../blocks/notifications/src/Notifications";
import EmailLists from "../../blocks/EmailLists/src/EmailLists";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import DocusignIntegration from "../../blocks/DocusignIntegration/src/DocusignIntegration";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import TermsAndConditions from "../../blocks/TermsAndConditions/src/TermsAndConditions";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import DownloadOptions from "../../blocks/DownloadOptions/src/DownloadOptions";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import EmailNotifications2 from "../../blocks/EmailNotifications2/src/EmailNotifications2";
import TaskGroups from "../../blocks/TaskGroups/src/TaskGroups";
import ElasticSearch from "../../blocks/ElasticSearch/src/ElasticSearch";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import LandingPage from "../../blocks/landingpage/src/LandingPage";



const routeMap = {
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
TaskAllocator:{
 component:TaskAllocator,
path:"/TaskAllocator"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
Documentation:{
 component:Documentation,
path:"/Documentation"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
DocumentDistribution:{
 component:DocumentDistribution,
path:"/DocumentDistribution"},
ProposalGeneration2:{
 component:ProposalGeneration2,
path:"/ProposalGeneration2"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
AutomatedEmailSending:{
 component:AutomatedEmailSending,
path:"/AutomatedEmailSending"},
AdminConsole2:{
 component:AdminConsole2,
path:"/AdminConsole2"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
EmailLists:{
 component:EmailLists,
path:"/EmailLists"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
Settings5:{
 component:Settings5,
path:"/Settings5"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
DocusignIntegration:{
 component:DocusignIntegration,
path:"/DocusignIntegration"},
UploadMedia2:{
 component:UploadMedia2,
path:"/UploadMedia2"},
TermsAndConditions:{
 component:TermsAndConditions,
path:"/TermsAndConditions"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
DownloadOptions:{
 component:DownloadOptions,
path:"/DownloadOptions"},
Notificationsettings:{
 component:Notificationsettings,
path:"/Notificationsettings"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
EmailNotifications2:{
 component:EmailNotifications2,
path:"/EmailNotifications2"},
TaskGroups:{
 component:TaskGroups,
path:"/TaskGroups"},
ElasticSearch:{
 component:ElasticSearch,
path:"/ElasticSearch"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},

  Home: {
component:DocumentDistribution,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
