import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  Typography,
  TextField,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import DocumentDistributionController, {
  configJSON,
} from "./DocumentDistributionController.web";
import { Props } from "./DocumentDistributionRootController";

export default class DocumentDistribution extends DocumentDistributionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box>
            <Typography variant="h3" style={{ textAlign: "center" }}>
              {configJSON.labelTitleText}
            </Typography>
            <div>
              <Box style={{ marginTop: "20px" }}>
                <Box>
                  <Typography variant="h6">
                    Enter account ID to share to
                  </Typography>
                </Box>
                <TextField
                  data-test-id="InputSharedId"
                  variant="outlined"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  fullWidth={true}
                  onChange={(e) => this.setState({ sharedId: e.target.value })}
                />
                <Box style={{ marginTop: "20px" }}>
                  <Input
                    data-test-id="InputFile"
                    type="file"
                    onChange={this.onFileChange}
                  />
                  <Button
                    data-test-id="UploadBtn"
                    disabled={!(this.state.sharedId && this.state.selectedFile)}
                    onClick={this.onFileUpload}
                  >
                    {configJSON.UploadBtn}
                  </Button>
                </Box>
              </Box>
            </div>

            <div>
              <Button
                data-test-id="GoToShared"
                onClick={() => this.props.navigation.navigate("SharedDocument")}
              >
                {configJSON.SharedDocumentBtn}
              </Button>
            </div>
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
