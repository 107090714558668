import DocumentDistributionRootController from "./DocumentDistributionRootController";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export default class DocumentDistributionController extends DocumentDistributionRootController {
  // Customizable Area Start
  onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0] as File;
      this.setState({ selectedFile: file });
    }
  };

  getBase64 = (file: File): Promise<string> => {
    return new Promise((resolve) => {
      let baseURL: string | ArrayBuffer | null = "";
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        baseURL = reader.result;
        const startpoint = (baseURL as string).search("data");
        const endpoint = (baseURL as string).search("base64,");
        const str = (baseURL as string).slice(startpoint, endpoint + 7);
        baseURL = (baseURL as string).replace(str, "");
        resolve(baseURL);
      };
    });
  };

  onFileUpload = async () => {
    const result = await this.getBase64(this.state.selectedFile as File);

    const doc = {
      data: result,
      filename: this.state.selectedFile?.name,
      content_type: this.state.selectedFile?.type,
    };

    return this.sendRequest(doc);
  };
  // Customizable Area End
}
